import React, { useState, useEffect } from "react";
import { debounce } from "lodash";
import "../Profile/index.less";
import "./index.less";
import Reaulatory3 from "../../../assets/menu/Regulatory3.png";
import E from "../../../models/E";
import CustomEmpty from "@/utils/CustomEmpty";
import {
	Button,
	Row,
	Col,
	Space,
	Table,
	Form,
	Select,
	Empty,
	Spin,
} from "antd";
import sortData from "../../../utils/sorter";
import { effect, useStore } from "../../../utils/dva16";
import {
	EGet,
	NOrganizationMulti,
	EGetChart,
	EGetArea,
	NRegion,
	NOrganizationMultiType,
	NCommon,
	EGetActivetType,
} from "../../../models/contants";
import { Pie, G2 } from "@ant-design/charts";
import { customizeRenderEmpty } from "../../../utils/utils";
const { Option } = Select;
export default (props) => {
	const { history } = props;
	const [form] = Form.useForm();
	const { areaList, commonAreaList } = useStore(NRegion);
	const [keyword, setKeyword] = useState({});
	const { OrganizationMultiList, OrganizationMultiChart, loading } =
		useStore(NOrganizationMulti);
	const { OrganizationMultiTypeList } = useStore(NOrganizationMultiType);
	const { activityTypeList } = useStore(NCommon);
	let typeArr = ["Regulatory", "Government"];
	const [sortState, setSortState] = useState(null);
	let sortType = {
		ascend: "asc",
		descend: "desc",
	};
	useEffect(() => {
		effect(NCommon, EGetActivetType, { page: 1, perPage: 100 });
		effect(NOrganizationMultiType, EGet, { page: 1, perPage: 10 });
		effect(NOrganizationMulti, EGet, {
			category: typeArr,
		});
		effect(NOrganizationMulti, EGetChart, {
			category: typeArr,
		});
		effect(NRegion, EGetArea, { page: 1, perPage: 100 });
		return () => {};
	}, []);

	const CompanyCharts = () => {
		const G = G2.getEngine("canvas");

		const [config, setConfig] = useState({
			data: [],
			height: 300,
			appendPadding: 10,
			angleField: "count",
			colorField: "region",
			radius: 0.75,
			// legend: false,
			innerRadius: 0.6,
			forceFit: true,
			legend: {
				layout: "horizontal",
				position: "top",
			},
			statistic: {
				title: false,
				content: false,
			},
			label: {
				type: "spider",
				labelHeight: 40,
				style: {
					fontSize: 20,
				},
				formatter: (data, mappingData) => {
					const group = new G.Group({});
					group?.addShape({
						type: "circle",
						attrs: {
							x: 0,
							y: 0,
							width: 40,
							height: 50,
							r: 5,
							fill: mappingData?.color,
						},
					});
					group?.addShape({
						type: "text",
						attrs: {
							x: 10,
							y: 8,
							text: `${data?.region}`,
							fill: mappingData?.color,
						},
					});
					group?.addShape({
						type: "text",
						attrs: {
							x: 0,
							y: 25,
							text: `${data?.count} [${(data?.percent * 100)?.toFixed(2)}%]`,
							fill: "rgba(0, 0, 0, 0.65)",
							fontWeight: 700,
						},
					});
					return group;
				},
			},
			interactions: [
				{
					type: "element-selected",
				},
				{
					type: "element-active",
				},
			],
		});
		useEffect(() => {
			if (OrganizationMultiChart && OrganizationMultiChart?.data) {
				config.data = OrganizationMultiChart?.data;

				setConfig(Object.assign({}, config));
			}
		}, [OrganizationMultiChart]);
		return <Pie {...config} />;
	};

	const columns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			sorter: true,
			sortOrder: sortState?.field == "name" && sortState?.order,
		},
		{
			title: "Region",
			dataIndex: "region",
			key: "region",
			render: (text, record) => (
				<div>
					{record?.region?.length
						? record?.region?.map((vo, io) => <div key={io}>{vo}</div>)
						: "-"}
				</div>
			),
		},
		{
			title: "Area",
			dataIndex: "area",
			key: "area",
			render: (text, record) => (
				<div>
					{record?.area?.length
						? record?.area?.map((vo, io) => <div key={io}>{vo}</div>)
						: "-"}
				</div>
			),
		},
		{
			title: "Type",
			dataIndex: "type",
			key: "type",
			width: 200,
			render: (text, value, index) => (
				<>
					{text
						? text?.map((item, index) => <div key={index}>{item}</div>)
						: "-"}
				</>
			),
		},
		{
			title: "Category",
			dataIndex: "category",
			key: "category",
			sorter: true,
			sortOrder: sortState?.field == "category" && sortState?.order,
			render: (text, value, index) => <>{text ? text : "-"}</>,
		},
		{
			title: "Website",
			dataIndex: "website",
			key: "website",
			render: (text, value, index) => <>{text ? text : "-"}</>,
		},
		{
			title: "Activity",
			dataIndex: "activity",
			key: "activity",
			render: (text, value, index) => <>{text ? text : "-"}</>,
		},
	];
	const onFinish = (values) => {
		setKeyword(values);

		(values.category = values?.category || typeArr),
			effect(NOrganizationMulti, EGet, {
				...values,
				page: 1,
				perPage: 10,
				field: sortState?.field,
				isDesc: sortState?.order && sortType[sortState?.order],
			});
	};
	const [regionArr, setRegionArr] = useState([]);
	function findRegionType(arr) {
		const updatedRegionArr = [];
		commonsortData(areaList?.data, "area")?.forEach((item) => {
			if (arr?.includes(item?.area)) {
				updatedRegionArr?.push(item?.region);
			}
		});
		setRegionArr(Array.from(new Set(updatedRegionArr)));
		form.setFieldsValue({
			regions: Array.from(new Set(updatedRegionArr)),
		});
	}
	const onSearchType = (value) => {
		effect(NOrganizationMultiType, EGet, { page: 1, perPage: 10, name: value });
	};
	// ---------------------渲染-------------------------
	return (
		<div>
			<div className="TeamsBox Organizations_Box mt50">
				<Row gutter={24}>
					<Col span={10}>
						<div
							className="Statistic_Box Organizations_Statistic_Box BorderBox"
							style={{ minHeight: "300px" }}
						>
							<div className="Organizations_Statistic_Card">
								<img
									src={Reaulatory3}
									style={{ width: "90px", marginRight: 10 }}
								/>
								<div className="Statistic_Card_Sub">
									<div style={{ fontSize: "20px", color: "#999" }}>
										Total Organizations
									</div>
									<h2 style={{ fontSize: "40px", textAlign: "left" }}>
										{OrganizationMultiChart?.count
											?.toString()
											.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
									</h2>
								</div>
							</div>
						</div>
					</Col>
					<Col span={14}>
						<div
							className="CompanyChartBox BorderBox"
							style={{ minHeight: "300px", paddingTop: 20 }}
						>
							<span>{CompanyCharts()}</span>
						</div>
					</Col>
				</Row>
			</div>
			<Spin spinning={loading} size="large">
				<div
					className="BlogBox mt50"
					style={{ backgroundColor: "#F7F9FB", padding: "40px 40px 20px 0px" }}
				>
					<Form
						name="advanced_search"
						className="ant-advanced-search-form"
						form={form}
						labelCol={{ span: 8 }}
						onFinish={onFinish}
					>
						<Row>
							<Col span={8}>
								<Form.Item name="area" label="Area">
									<Select
										showArrow
										getPopupContainer={(triggerNode) => triggerNode.parentNode}
										mode="multiple"
										placeholder="Search"
										showSearch
										onChange={(e) => {
											findRegionType(e);
										}}
										onSearch={debounce((value) => {
											const { page, perPage } = areaList;
											effect(NRegion, EGetArea, {
												page,
												perPage,
												area: value,
											});
										}, 300)}
										filterOption={false}
									>
										{sortData(areaList?.data, "area")?.map((record, index) => {
											return (
												<Option key={record?.id} value={record?.area}>
													{record?.area}
												</Option>
											);
										})}
									</Select>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item name="regions" label="Regions">
									<Select showArrow mode="multiple" placeholder="Select">
										{E?.mainEnum?.map((vo, index) => (
											<Option key={index} value={vo?.text}>
												{vo?.text}
											</Option>
										))}
									</Select>
								</Form.Item>
							</Col>

							<Col span={8}>
								<Form.Item name="activity" label="Activity">
									<Select
										showArrow
										getPopupContainer={(triggerNode) => triggerNode.parentNode}
										placeholder="Search"
										showSearch
										filterOption={false}
										onSearch={debounce((value) => {
											const { page, perPage } = activityTypeList;
											effect(NRegion, EGetArea, {
												page,
												perPage,
												type: value,
											});
										}, 300)}
									>
										{sortData(activityTypeList?.data, "type")?.map(
											(record, index) => {
												return (
													<Option key={record?.id} value={record?.type}>
														{record?.type}
													</Option>
												);
											}
										)}
									</Select>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item label="Category" name="category">
									<Select
										getPopupContainer={(triggerNode) => triggerNode.parentNode}
										placeholder="Select"
										showArrow
										showSearch
										filterOption={false}
									>
										<Option value="Regulatory">Regulatory</Option>
										<Option value="Government">Government</Option>
									</Select>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item label="Type" name="types">
									<Select
										getPopupContainer={(triggerNode) => triggerNode.parentNode}
										showSearch
										showArrow
										filterOption={false}
										placeholder="Select"
										mode="multiple"
										onSearch={debounce((value) => {
											onSearchType(value);
										}, 300)}
									>
										{sortData(OrganizationMultiTypeList)?.map((item, index) => {
											return (
												<Option key={index} value={item?.name}>
													{item?.name}
												</Option>
											);
										})}
									</Select>
								</Form.Item>
							</Col>
							<Col span={8} style={{ textAlign: "right" }}>
								<Space>
									<Button
										type="primary"
										htmlType="submit"
										className="primarySearch"
									>
										Search
									</Button>
									<Button
										className="secReset"
										type="default"
										onClick={() => {
											setKeyword();
											form.resetFields();
											setSortState(null);
											effect(NOrganizationMulti, EGet, {
												category: typeArr,
											});
										}}
									>
										Reset
									</Button>
								</Space>
							</Col>
						</Row>
					</Form>
				</div>

				<div className="TeamsBox Organizations_Box">
					<Row className="RowBox mt30">
						<Col span={24}>
							{OrganizationMultiList?.error == 1009 ? (
								customizeRenderEmpty()
							) : (
								<Table
									locale={{ emptyText: CustomEmpty }}
									showSorterTooltip={false}
									scroll={{ x: "max-content" }}
									dataSource={OrganizationMultiList?.data || []}
									columns={columns}
									className="CompanyTableBox"
									rowKey="id"
									pagination={{
										current:
											parseInt(
												OrganizationMultiList && OrganizationMultiList?.page
											) || 1,
										total:
											(OrganizationMultiList && OrganizationMultiList?.total) ||
											0,
										defaultCurrent: 1,
										defaultPageSize:
											parseInt(
												OrganizationMultiList && OrganizationMultiList?.perPage
											) || 10,
										pageSize:
											OrganizationMultiList && OrganizationMultiList?.perPage,
										pageSizeOptions: ["10", "20", "30", "50", "100"],
										showQuickJumper: true,
										showSizeChanger: true,
										position: "bottom",
									}}
									onChange={(pagination, filter, sort) => {
										setSortState(sort);

										effect(NOrganizationMulti, EGet, {
											...keyword,
											category: keyword?.category || typeArr,
											page: pagination?.current,
											perPage: pagination?.pageSize,
											field: sort?.column && sort?.field,
											isDesc:
												(sort?.column &&
													sort?.order &&
													sortType[sort?.order]) ||
												null,
										});
									}}
								/>
							)}
						</Col>
					</Row>
				</div>
			</Spin>
		</div>
	);
};
