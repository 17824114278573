import React, { useState, useEffect } from "react";
import moment from "moment";
import { debounce } from "lodash";
import "./index.less";
import CustomEmpty from "@/utils/CustomEmpty";
import {
	Table,
	Form,
	Select,
	Pagination,
	Row,
	Col,
	Spin,
	Space,
	Button,
	Tabs,
} from "antd";
import { effect, useStore } from "../../../utils/dva16";
import {
	EGet,
	NRevenueOverAll,
	EGetCollect,
	NCompany,
} from "../../../models/contants";

const { TabPane } = Tabs;
export default (props) => {
	const { history } = props;
	const [form] = Form.useForm();
	const [lastSearch, setLastSeach] = useState({});
	const { loading, revenueList, page, perPage } = useStore(NRevenueOverAll);
	const { companyData } = useStore(NCompany);
	const [current, setCurrent] = useState(
		moment().year(moment().year()).format("YYYY").toString()
	);

	const screencolumns = [
		{
			title: "Rank",
			dataIndex: "rank",
			align: "center",
			render: (text, record, index) => {
				return (
					<div className={`rank rank${index + 1 + (page - 1) * perPage}`}>
						{index + 1 + (page - 1) * perPage}
					</div>
				);
			},
		},
		{
			title: "Company name",
			dataIndex: "companyInfo",
			defaultSortOrder: "companyInfo",
			render: (text, value, index) => (
				<>
					<p
						style={{ cursor: "pointer", textDecoration: "underline" }}
						className={"a_style"}
						onClick={() => {
							history.push(
								`/games_organizations/organizations/details/${text?.id}`
							);
						}}
					>
						{text?.nameEn || "-"}
					</p>
					<p
						style={{ cursor: "pointer", textDecoration: "underline" }}
						className={"a_style"}
						onClick={() => {
							history.push(
								`/games_organizations/organizations/details/${text?.id}`
							);
						}}
					>
						{text?.nameCn || "-"}
					</p>
				</>
			),
		},
		{
			title: "Games revenue(Jan-Mar)",
			dataIndex: "q1Total",
			defaultSortOrder: "q1Total",
			render: (text, value, index) =>
				text?.toString().replace(/(\d)(?=(\d{3})+\b)/g, "$1,"),
		},
		{
			title: "Games revenue(Apr-Jun)",
			dataIndex: "q2Total",
			defaultSortOrder: "q2Total",
			render: (text, value, index) =>
				text?.toString().replace(/(\d)(?=(\d{3})+\b)/g, "$1,"),
		},
		{
			title: "Games revenue(Jul-Sep)",
			dataIndex: "q3Total",
			defaultSortOrder: "q3Total",
			render: (text, value, index) =>
				text?.toString().replace(/(\d)(?=(\d{3})+\b)/g, "$1,"),
		},
		{
			title: "Games revenue(Oct-Dec)",
			dataIndex: "q4Total",
			defaultSortOrder: "q4Total",
			render: (text, value, index) =>
				text?.toString().replace(/(\d)(?=(\d{3})+\b)/g, "$1,"),
		},
		{
			title: "Total",
			dataIndex: "Total",
			key: "Total",
			render: (text, value, index) =>
				text?.toString().replace(/(\d)(?=(\d{3})+\b)/g, "$1,"),
		},
	];
	useEffect(() => {
		effect(NRevenueOverAll, EGetCollect, {
			page: 1,
			perPage: 10,
			year: current,
		});
		effect(NCompany, EGet, { page: 1, perPage: 10 });
		return () => {};
	}, []);
	// ---------------------响应函数-------------------------

	const onPageChange = (page, pageSize) => {
		effect(NRevenueOverAll, EGetCollect, {
			page: page,
			perPage: pageSize,
			...lastSearch,
			year: current,
		});
	};
	const onReset = () => {
		form.resetFields();
		effect(NRevenueOverAll, EGetCollect, {
			page: 1,
			perPage: 10,
			year: current,
		});
		setLastSeach();
	};
	const onSearchCompany = (value) => {
		effect(NCompany, EGet, { ...{ company: value }, page: 1, perPage: 10 });
	};
	const onFinish = (value) => {
		effect(NRevenueOverAll, EGetCollect, { ...value, year: current });
		setLastSeach({ ...value });
	};

	return (
		<div
			className="companyRevenue_Box CompanyDetail_Box DetailBg wd100"
			style={{ padding: "20px 0" }}
		>
			<Spin spinning={loading} size="large">
				<div
					className="BlogBox MaIpoData_Box mt50"
					style={{ backgroundColor: "#F7F9FB", padding: "40px 40px 20px 0px" }}
				>
					<Form
						name="advanced_search"
						className="ant-advanced-search-form"
						labelCol={{ span: 8 }}
						form={form}
						onFinish={onFinish}
					>
						<Row gutter={24}>
							<Col span={8}>
								<Form.Item name="companyId" label="Company">
									<Select
										showArrow
										getPopupContainer={(triggerNode) => triggerNode.parentNode}
										showSearch
										filterOption={false}
										placeholder="Select"
										onSearch={debounce((value) => {
											onSearchCompany(value);
										}, 300)}
									>
										{companyData?.data?.map((item, index) => {
											return (
												<Option key={index} value={item?.id}>
													{item?.nameEn}
												</Option>
											);
										})}
									</Select>
								</Form.Item>
							</Col>

							<Col span={8} style={{ textAlign: "left" }}>
								<Space>
									<Button
										type="primary"
										htmlType="submit"
										className="primarySearch"
									>
										Search
									</Button>
									<Button
										type="default"
										htmlType="button"
										onClick={onReset}
										className="secReset"
									>
										Reset
									</Button>
								</Space>
							</Col>
						</Row>
					</Form>
				</div>
				<div className="BlogBox mt20">
					<div className="RowBox BorderBox">
						<div className="Global_title">
							<h2>Game Companies ranked by Revenue (USD million)</h2>
						</div>
						<Tabs
							activeKey={current}
							onChange={(activeKey) => {
								setCurrent(activeKey);
								effect(NRevenueOverAll, EGetCollect, {
									page: 1,
									perPage: 10,
									year: activeKey,
									...lastSearch,
								});
							}}
							style={{ padding: "0 20px" }}
						>
							<TabPane
								tab={moment().format("YYYY").toString()}
								key={moment().format("YYYY").toString()}
							></TabPane>
							<TabPane
								tab={moment().format("YYYY").toString() - 1}
								key={moment().format("YYYY").toString() - 1}
							></TabPane>
						</Tabs>
						<div className="Insights_List">
							<Table
								locale={{ emptyText: CustomEmpty }}
								scroll={{ x: "max-content" }}
								columns={screencolumns}
								dataSource={revenueList?.data}
								rowKey="id"
								pagination={false}
							/>
						</div>
					</div>

					<Row>
						<Col span={24} style={{ textAlign: "right", marginTop: "20px" }}>
							{revenueList && (
								<Pagination
									current={parseInt(revenueList?.page)}
									total={parseInt(revenueList?.total)}
									pageSize={parseInt(revenueList?.perPage)}
									onChange={onPageChange}
									showQuickJumper
									showSizeChanger
								/>
							)}
						</Col>
					</Row>
				</div>
			</Spin>
		</div>
	);
};
