import React, { useState, useEffect } from "react";
import moment from "moment";
import { debounce } from "lodash";
import copy from "copy-to-clipboard";
import "./index.less";
import "../Profile/index.less";
import iconFilter from "@/assets/icon/icon_search.png";
import sortData from "../../../utils/sorter";
import CustomModal from "../../../components/modal";
import formatDate from "@/utils/dateFormater";
import CustomEmpty from "@/utils/CustomEmpty";
import {
	Button,
	Row,
	Divider,
	Col,
	Space,
	Tag,
	Form,
	Input,
	Typography,
	Select,
	Pagination,
	Empty,
	Spin,
	message,
	Tooltip,
	DatePicker,
} from "antd";
import { effect, useStore } from "../../../utils/dva16";
import {
	NCommon,
	EGet,
	EGetType,
	NGame,
	NGenre,
	NPlatform,
	NInsightsFeed,
	NRegion,
	EGetArea,
	EGetSponsor,
	EGetAgencies,
	NOrganizationMulti,
	EGetCompany,
	EGetAll,
	NLogin,
	EGetRegion,
	EGetTypeSelect,
	NInsightType,
} from "../../../models/contants";
import { CopyOutlined } from "@ant-design/icons";
import E from "../../../models/E";
import { customizeRenderEmpty } from "../../../utils/utils";
import { Helmet } from "react-helmet";
import Organization from "./../../../models/OrganizationMulti";
import imgSource from "../../../assets";

const { Option } = Select;
export default (props) => {
	const { RangePicker } = DatePicker;
	const { history } = props;
	const { TypeSelectlist } = useStore(NInsightType);
	const { Text } = Typography;

	const {
		location: { state },
	} = history;
	const [form] = Form.useForm();
	const { InsightsFeedList, loading } = useStore(NInsightsFeed);
	const { OrganizationAll } = useStore(NOrganizationMulti);
	const { areaList, regionList, commonAreaList } = useStore(NRegion);
	const { gameSearch, GameList, gameTitle, GameDetail } = useStore(NGame);
	const [filterValues, setFilterValues] = useState([]);
	const { GenreList } = useStore(NGenre);
	const { currentSubscribe, userInfo } = useStore(NLogin);
	const { PlatformData, TypeList } = useStore(NPlatform);
	// const { sponsorList, agencyList } = useStore(NCommon);
	const [searchValues, setSearchValues] = useState({});
	const [bigType, setBigType] = useState(null);
	const paginationLocale = {
		items_per_page: "per page",
	};
	const [showAllTags, setShowAllTags] = useState({});

	const toggleShowAll = (index) => {
		setShowAllTags((prevState) => ({
			...prevState,
			[index]: !prevState[index],
		}));
	};
	useEffect(() => {
		if (state) {
			form.setFieldsValue({
				...state,
			});
			if (state.company) {
				setFilterValues((o) => [...o, state.company]);
				form.setFieldsValue({
					company: state?.name,
				});
			}
			if (state.game) {
				setFilterValues((o) => [...o, state.game]);
				form.setFieldsValue({
					game: state?.name,
				});
			}
			setSearchValues({ ...searchValues, ...state });
		}

		let page = sessionStorage.getItem("vanaPage");
		let pageSize = sessionStorage.getItem("vanaPageSize");
		if (page && pageSize) {
			effect(NInsightsFeed, EGet, { page, pageSize });
		} else {
			effect(NInsightsFeed, EGet, {
				page: 1,
				perPage: 10,
				field: "date",
				isDesc: "desc",
				...state,
			});
		}
		effect(NCommon, EGetSponsor, { page: 1, perPage: 10 });
		effect(NCommon, EGetAgencies, { page: 1, perPage: 10 });
		effect(NOrganizationMulti, EGetAll, {
			page: 1,
			perPage: 10,
			id: state?.company,
		});
		// effect(NOrganizationMulti, EGetCompany, {
		// 	page: 1,
		// 	perPage: 10,
		// 	id: state?.company,
		// });
		effect(NInsightType, EGetTypeSelect, { page: 1, perPage: 10 });
		effect(NPlatform, EGet, { page: 1, perPage: 10 });
		effect(NGenre, EGet, { page: 1, perPage: 10 });
		// onSearchGame(state?.game);
		effect(NGame, EGetType, { page: 1, perPage: 10 });
		effect(NGame, EGet, { page: 1, perPage: 10, id: state?.game });
		effect(NPlatform, EGetType, {});
		effect(NRegion, EGetArea, { page: 1, perPage: 10 });
		effect(NLogin, EGet, {});
		effect(NRegion, EGetRegion, { page: 1, perPage: 10 });
		sessionStorage.removeItem("page");
		sessionStorage.removeItem("lastPage");
		sessionStorage.removeItem("perPage");
		sessionStorage.removeItem("arr");
		sessionStorage.removeItem("index");
	}, []);

	function removeItalicsMarkdown(text) {
		let result = text
			.normalize("NFKD")
			.normalize("NFD")
			.replace(/[\u0300-\u036f]/g, "");

		return result;
	}
	const onPageChange = (page, pageSize) => {
		effect(NInsightsFeed, EGet, {
			page,
			perPage: pageSize,
			field: "date",
			isDesc: "desc",
			...searchValues,
		});
		sessionStorage.setItem("vanaPage", page);
		sessionStorage.setItem("vanaPageSize", pageSize);
	};
	const onSearchCompany = (value) => {
		effect(NOrganizationMulti, EGetAll, {
			name: value,
			page: 1,
			perPage: 10,
		});
	};
	const onSearchGame = (value) => {
		effect(NGame, EGetType, { ...{ name: value }, page: 1, perPage: 10 });
	};
	const onSearchGenre = (value) => {
		effect(NGenre, EGet, { ...{ genre: value }, page: 1, perPage: 10 });
	};
	const onSearchPlatform = (value) => {
		effect(NPlatform, EGet, { ...{ platform: value }, page: 1, perPage: 10 });
	};
	// const onSearchSponsor = (value) => {
	// 	effect(NCommon, EGetSponsor, {
	// 		company: value,
	// 		page: 1,
	// 		perPage: 10,
	// 	});
	// };
	const handleTypeSearch = (e) => {
		setBigType(e);
		effect(NInsightType, EGetTypeSelect, { page: 1, BigType: e });
	};
	// const onSearchAgency = (value) => {
	// 	effect(NCommon, EGetAgencies, {
	// 		company: value,
	// 		page: 1,
	// 		perPage: 10,
	// 	});
	// };
	const [isModalOpen, setIsModalOpen] = useState(false);

	const showModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};
	const onFinish = (values) => {
		// if (values?.date) {
		if (state?.company) {
			values.company = state?.company;
		}
		if (state?.game) {
			values.game = state?.game;
		}
		values.startDate = values?.startDate
			? moment(values?.startDate?._d)?.format("YYYY-MM-DD")
			: null;
		values.endDate = values?.endDate
			? moment(values?.endDate?._d)?.format("YYYY-MM-DD")
			: null;
		const valuesArray = Object.values(values).filter(
			(val) =>
				val !== undefined &&
				val !== null &&
				!(Array.isArray(val) && val.length === 0)
		);
		setFilterValues(valuesArray);
		// values.startDate = moment(values?.date[0])?.format("YYYY-MM-DD");
		// values.endDate = moment(values?.date[1])?.format("YYYY-MM-DD");
		// }
		values.platform = values?.platform?.map((areaValue) => {
			return PlatformData?.data?.find((item) => item?.lookupValue === areaValue)
				?.id;
		});
		// values.genres = values?.genres?.map((genreValue) => {
		// 	return GenreList?.data?.find((item) => item?.lookupValue === genreValue)
		// 		?.id;
		// });
		values.area = values.area && [
			...values?.area?.map((areaValue) => {
				return commonAreaList?.data?.find(
					(item) => item?.otherData === areaValue
				)?.id;
			}),
			...values.area,
		];
		setSearchValues(values);
		delete values?.date;
		closeModal();
		effect(NInsightsFeed, EGet, { ...values, page: 1, perPage: 10 });
	};

	const onReset = () => {
		sessionStorage.setItem("vanaPage", 1);
		setFormValues([]);
		sessionStorage.setItem("vanaPageSize", 10);
		setBigType(null);
		setSearchValues();
		setFilterValues([]);
		form.resetFields();
		closeModal();
		effect(NInsightsFeed, EGet, { page: 1, perPage: 10 });
	};
	const toDetails = (value) => {
		//单击列表中的某文章时查找对应id所在下标更新URL，并将该项目的相关信息存储到sessionStorage中。 | When clicking on an article in the list, search for the corresponding index update URL where the ID is located, and store the relevant information of the item in SessionStorage
		const index = InsightsFeedList?.data?.findIndex(
			(item) => item?.id === value
		);
		history.push({
			pathname: `/news_articles/vana/details/${value}`,
		});
		sessionStorage.setItem("page", InsightsFeedList?.page);
		sessionStorage.setItem("lastPage", InsightsFeedList?.lastPage);
		sessionStorage.setItem("perPage", InsightsFeedList?.perPage);
		sessionStorage.setItem("arr", JSON.stringify(InsightsFeedList?.data));
		sessionStorage.setItem("index", index);
	};

	function findRegionType(arr) {
		const updatedRegionArr = [];

		areaList?.data?.forEach((item) => {
			if (arr?.includes(item?.otherData)) {
				updatedRegionArr?.push(item?.lookupValue);
			}
		});

		form.setFieldsValue({
			region: Array.from(new Set(updatedRegionArr)),
		});
		setFormValues((prevValues) => ({
			...prevValues,
			["region"]: Array.from(new Set(updatedRegionArr)),
		}));
	}
	// ---------------------渲染-------------------------
	const [formValues, setFormValues] = useState({});

	// Handle form value changes
	const handleValuesChange = (changedValues) => {
		setFormValues((prevValues) => ({
			...prevValues,
			...changedValues,
		}));
	};

	// Reset field function
	const resetField = (fieldName) => {
		if (fieldName === "area") {
			findRegionType([]); // Clear region based on empty area
		}
		form.setFieldsValue({ [fieldName]: [] });
		setFormValues((prevValues) => ({
			...prevValues,
			[fieldName]: [],
		}));
		let values = form.getFieldsValue();
		setFilterValues(values);
	};
	const copyVanaTop = (e, vo) => {
		e.preventDefault();
		if (vo?.content) {
			navigator.clipboard
				.writeText(vo?.content?.replace(/<[^>]+>/g, ""))
				.then(() => {
					console.log("Content copied to clipboard!");
				})
				.catch((err) => {
					console.error("Failed to copy: ", err);
				});
		}
	};

	const searchByTags = (state) => {
		effect(NInsightsFeed, EGet, {
			page: 1,
			perPage: 10,
			field: "date",
			isDesc: "desc",
			...state,
		});
		if (state) {
			form.setFieldsValue({
				...state,
			});
		}
	};
	return (
		<div>
			<Helmet>
				<script
					dangerouslySetInnerHTML={{
						__html: `
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','GTM-N9T2Q4PW');
                        `,
					}}
				/>
				{/* Google tag (gtag.js) */}
				<script
					async
					src="https://www.googletagmanager.com/gtag/js?id=G-6049TJDB50"
				></script>
				<script>
					{`
         				window.dataLayer = window.dataLayer || [];
        				function gtag(){dataLayer.push(arguments);}
          			gtag('js', new Date());
         				gtag('config', 'G-6049TJDB50');
        	`}
				</script>
			</Helmet>
			<div>
				<p className="mainheader">VANA</p>
				<div className="subHeader">
					Filter the knowledge base for VANA data.
				</div>
			</div>
			{userInfo?.type === "Admin" ||
			(currentSubscribe?.[0]?.module.hasOwnProperty("InsightsFeed") &&
				currentSubscribe?.[0]?.module["InsightsFeed"]?.length > 0) ? (
				<>
					<div className="BlogBox mt50 listSearch">
						<div>
							<Form
								onValuesChange={handleValuesChange}
								form={form}
								onFinish={onFinish}
								className="searchFormCard"
							>
								{" "}
								<Row>
									<Form.Item name="keyWord" labelCol={{ span: 24 }}>
										<Input
											style={{ marginTop: "10px" }}
											className="customInput"
											placeholder="Search by Keyword"
										/>
									</Form.Item>
									<Button
										type="primary"
										htmlType="submit"
										className="applySearchBtn"
									>
										<img src={iconFilter} />
									</Button>
								</Row>
							</Form>
						</div>
						<CustomModal
							isModalOpen={isModalOpen}
							showModal={showModal}
							closeModal={closeModal}
							filterValues={filterValues}
							content={
								<>
									{" "}
									<Form
										name="advanced_search"
										className="ant-advanced-search-form"
										form={form}
										onValuesChange={handleValuesChange}
										onFinish={onFinish}
									>
										<Row>
											<Col span={24}>
												<Form.Item
													name="area"
													labelCol={{ span: 24 }}
													label={
														<div className="headBox">
															<Text className="label" type="secondary">
																AREA
															</Text>
															<Button
																disabled={
																	!formValues.area ||
																	formValues.area.length === 0
																}
																className={
																	!formValues.area ||
																	formValues.area.length === 0
																		? "resetDisabled"
																		: "reset"
																}
																onClick={() => resetField("area")}
																type="secondary"
																style={{
																	marginLeft: "auto",
																	cursor: "pointer",
																}}
															>
																Reset
															</Button>
														</div>
													}
												>
													<Select
														getPopupContainer={(triggerNode) =>
															triggerNode?.parentNode
														}
														mode="multiple"
														showArrow
														placeholder="Select"
														showSearch
														onChange={(e) => {
															findRegionType(e);
														}}
														onSearch={debounce((value) => {
															const { page, perPage } = areaList;
															effect(NRegion, EGetArea, {
																page,
																perPage,
																area: value,
															});
														}, 300)}
														filterOption={(input, option) =>
															option?.children
																?.toLowerCase()
																.indexOf(input?.toLowerCase()) >= 0
														}
													>
														{sortData(areaList?.data, "area")?.map(
															(record, index) => {
																return (
																	<Option
																		key={record?.id}
																		value={record?.otherData}
																	>
																		{record?.otherData}
																	</Option>
																);
															}
														)}
													</Select>
												</Form.Item>
											</Col>
										</Row>

										<Row>
											<Col span={24}>
												<Form.Item
													name="region"
													labelCol={{ span: 24 }}
													label={
														<div className="headBox">
															<Text className="label" type="secondary">
																REGION
															</Text>
															<Button
																disabled={
																	!formValues.region ||
																	formValues.region.length === 0
																}
																className={
																	!formValues.region ||
																	formValues.region.length === 0
																		? "resetDisabled"
																		: "reset"
																}
																onClick={() => resetField("region")}
																type="secondary"
																style={{
																	marginLeft: "auto",
																	cursor: "pointer",
																}}
															>
																Reset
															</Button>
														</div>
													}
												>
													<Select
														mode="multiple"
														showArrow
														getPopupContainer={(triggerNode) =>
															triggerNode?.parentNode
														}
														filterOption={(input, option) =>
															option?.children
																?.toLowerCase()
																?.indexOf(input?.toLowerCase()) >= 0
														}
														placeholder="Select"

														// onSearch={debounce((value) => {
														// 	const { page, perPage } = areaList;
														// 	effect(NRegionArea, EGetRegion, {
														// 		page,
														// 		perPage,
														// 		region: value,
														// 	});
														// }, 300)}
													>
														{regionList?.map((vo, index) => (
															<Option key={index} value={vo?.lookupValue}>
																{vo?.lookupValue}
															</Option>
														))}
													</Select>
												</Form.Item>
											</Col>
										</Row>

										<Row>
											<Col span={24}>
												<Form.Item
													name="keyWord"
													rules={[
														{
															required: false,
															message: "Input something!",
														},
													]}
													labelCol={{ span: 24 }}
													label={
														<div className="headBox">
															<Text className="label" type="secondary">
																KEYWORD SEARCH
															</Text>
															<Button
																disabled={
																	!formValues.keyWord ||
																	formValues.keyWord.length === 0
																}
																className={
																	!formValues.keyWord ||
																	formValues.keyWord.length === 0
																		? "resetDisabled"
																		: "reset"
																}
																onClick={() => resetField("keyWord")}
																type="secondary"
																style={{
																	marginLeft: "auto",
																	cursor: "pointer",
																}}
															>
																Reset
															</Button>
														</div>
													}
												>
													<Input placeholder="Search" autoComplete="off" />
												</Form.Item>
											</Col>
										</Row>

										<Row>
											<Col span={24}>
												<Form.Item
													labelCol={{ span: 24 }}
													label={
														<div className="headBox">
															<Text className="label" type="secondary">
																PUBLISHED DATE
															</Text>
															<Form.Item shouldUpdate>
																{({ getFieldValue }) => {
																	const startDate = getFieldValue("startDate");
																	const endDate = getFieldValue("endDate");
																	const isDisabled = !startDate && !endDate;
																	return (
																		<Text
																			disabled={isDisabled}
																			className={
																				isDisabled ? "resetDisabled" : "reset"
																			}
																			onClick={() => {
																				form.resetFields([
																					"startDate",
																					"endDate",
																				]);
																			}}
																			type="secondary"
																			style={{
																				marginLeft: "auto",
																				cursor: "pointer",
																			}}
																		>
																			Reset
																		</Text>
																	);
																}}
															</Form.Item>
														</div>
													}
												>
													<Row gutter={16}>
														<Col span={12}>
															<Form.Item name="startDate" noStyle>
																<DatePicker
																	format="MMM DD, YYYY"
																	getPopupContainer={(triggerNode) =>
																		triggerNode.parentNode
																	}
																	style={{ width: "100%" }}
																	placeholder="Start Date"
																/>
															</Form.Item>
														</Col>
														<Col span={12}>
															<Form.Item name="endDate" noStyle>
																<DatePicker
																	format="MMM DD, YYYY"
																	getPopupContainer={(triggerNode) =>
																		triggerNode.parentNode
																	}
																	style={{ width: "100%" }}
																	placeholder="End Date"
																/>
															</Form.Item>
														</Col>
													</Row>
												</Form.Item>
											</Col>
										</Row>

										<Row>
											<Col span={24}>
												<Form.Item
													name="type"
													rules={[
														{
															required: false,
															message: "Input something!",
														},
													]}
													labelCol={{ span: 24 }}
													label={
														<div className="headBox">
															<Text className="label" type="secondary">
																CATEGORY
															</Text>
															<Button
																disabled={
																	!formValues.type ||
																	formValues.type.length === 0
																}
																className={
																	!formValues.type ||
																	formValues.type.length === 0
																		? "resetDisabled"
																		: "reset"
																}
																onClick={() => resetField("type")}
																type="secondary"
																style={{
																	marginLeft: "auto",
																	cursor: "pointer",
																}}
															>
																Reset
															</Button>
														</div>
													}
												>
													<Select
														getPopupContainer={(triggerNode) =>
															triggerNode?.parentNode
														}
														showArrow
														onChange={(value) => {
															handleTypeSearch(value);
														}}
														filterOption={(input, option) =>
															option?.children
																?.toLowerCase()
																.indexOf(input?.toLowerCase()) >= 0
														}
														placeholder="Select"
													>
														{sortData(TypeList?.mianType, "type")?.map(
															(item, index) => {
																return (
																	<Option key={index} value={item?.type}>
																		{item?.type}
																	</Option>
																);
															}
														)}
													</Select>
												</Form.Item>
											</Col>
										</Row>

										<Row>
											<Col span={24}>
												<Form.Item
													name="subType"
													labelCol={{ span: 24 }}
													label={
														<div className="headBox">
															<Text className="label" type="secondary">
																SUB-CATEGORY
															</Text>
															<Button
																disabled={
																	!formValues.subType ||
																	formValues.subType.length === 0
																}
																className={
																	!formValues.subType ||
																	formValues.subType.length === 0
																		? "resetDisabled"
																		: "reset"
																}
																onClick={() => resetField("subType")}
																type="secondary"
																style={{
																	marginLeft: "auto",
																	cursor: "pointer",
																}}
															>
																Reset
															</Button>
														</div>
													}
												>
													<Select
														getPopupContainer={(triggerNode) =>
															triggerNode?.parentNode
														}
														showSearch
														showArrow
														placeholder="Select"
														disabled={bigType ? false : true}
													>
														{sortData(TypeSelectlist?.subType, "type")?.map(
															(item, index) => (
																<Option
																	key={item?.id || index}
																	value={item?.subType}
																>
																	{item?.subType}
																</Option>
															)
														)}
													</Select>
												</Form.Item>
											</Col>
										</Row>

										<Row>
											<Col span={24}>
												<Form.Item
													name="game"
													labelCol={{ span: 24 }}
													label={
														<div className="headBox">
															<Text className="label" type="secondary">
																GAME
															</Text>
															<Button
																disabled={
																	!formValues.game ||
																	formValues.game.length === 0
																}
																className={
																	!formValues.game ||
																	formValues.game.length === 0
																		? "resetDisabled"
																		: "reset"
																}
																onClick={() => resetField("game")}
																type="secondary"
																style={{
																	marginLeft: "auto",
																	cursor: "pointer",
																}}
															>
																Reset
															</Button>
														</div>
													}
												>
													<Select
														getPopupContainer={(triggerNode) =>
															triggerNode?.parentNode
														}
														showSearch
														showArrow
														filterOption={(input, option) =>
															option?.children
																?.toLowerCase()
																.indexOf(input?.toLowerCase()) >= 0
														}
														placeholder="Select"
														onSearch={debounce((value) => {
															onSearchGame(removeItalicsMarkdown(value));
														}, 100)}
													>
														{gameSearch &&
															GameList &&
															[
																...gameSearch?.data,
																...sortData(
																	GameList?.data?.filter(
																		(item) =>
																			!gameSearch?.data?.find(
																				({ id }) => item?.id === id
																			)
																	),
																	"gameEn"
																),
															]?.map((item, index) => {
																return (
																	<Option key={index} value={item?.id}>
																		{item?.gameEn}
																	</Option>
																);
															})}
													</Select>
												</Form.Item>
											</Col>
										</Row>

										<Row>
											<Col span={24}>
												<Form.Item
													name="company"
													labelCol={{ span: 24 }}
													label={
														<div className="headBox">
															<Text className="label" type="secondary">
																ORGANIZATION
															</Text>
															<Button
																disabled={
																	!formValues.company ||
																	formValues.company.length === 0
																}
																className={
																	!formValues.company ||
																	formValues.company.length === 0
																		? "resetDisabled"
																		: "reset"
																}
																onClick={() => resetField("company")}
																type="secondary"
																style={{
																	marginLeft: "auto",
																	cursor: "pointer",
																}}
															>
																Reset
															</Button>
														</div>
													}
												>
													<Select
														getPopupContainer={(triggerNode) =>
															triggerNode?.parentNode
														}
														showSearch
														showArrow
														filterOption={(input, option) =>
															option?.children
																?.toLowerCase()
																.indexOf(input?.toLowerCase()) >= 0
														}
														placeholder="Select"
														onSearch={debounce((value) => {
															onSearchCompany(value);
														}, 300)}
													>
														{sortData(OrganizationAll?.data)?.map(
															(item, index) => {
																return (
																	<Option key={index} value={item?.id}>
																		{item?.name}
																	</Option>
																);
															}
														)}
													</Select>
												</Form.Item>
											</Col>
										</Row>

										<Row>
											<Col span={24}>
												<Form.Item
													name="genres"
													labelCol={{ span: 24 }}
													label={
														<div className="headBox">
															<Text className="label" type="secondary">
																GENRE
															</Text>
															<Button
																disabled={
																	!formValues.genres ||
																	formValues.genres.length === 0
																}
																className={
																	!formValues.genres ||
																	formValues.genres.length === 0
																		? "resetDisabled"
																		: "reset"
																}
																onClick={() => resetField("genres")}
																type="secondary"
																style={{
																	marginLeft: "auto",
																	cursor: "pointer",
																}}
															>
																Reset
															</Button>
														</div>
													}
												>
													<Select
														getPopupContainer={(triggerNode) =>
															triggerNode?.parentNode
														}
														showSearch
														showArrow
														filterOption={(input, option) =>
															option?.children
																?.toLowerCase()
																.indexOf(input?.toLowerCase()) >= 0
														}
														placeholder="Select"
														onSearch={debounce((value) => {
															onSearchGenre(value);
														}, 300)}
													>
														{sortData(GenreList?.data, "genre")?.map(
															(item, index) => {
																return (
																	<Option key={index} value={item?.genre}>
																		{item?.lookupValue}
																	</Option>
																);
															}
														)}
													</Select>
												</Form.Item>
											</Col>
										</Row>

										<Row>
											<Col span={24}>
												<Form.Item
													name="platform"
													rules={[
														{
															required: false,
															message: "Input something!",
														},
													]}
													labelCol={{ span: 24 }}
													label={
														<div className="headBox">
															<Text className="label" type="secondary">
																PLATFORM
															</Text>
															<Button
																disabled={
																	!formValues.platform ||
																	formValues.platform.length === 0
																}
																className={
																	!formValues.platform ||
																	formValues.platform.length === 0
																		? "resetDisabled"
																		: "reset"
																}
																onClick={() => resetField("platform")}
																type="secondary"
																style={{
																	cursor: "pointer",
																}}
															>
																Reset
															</Button>
														</div>
													}
												>
													<Select
														className="select"
														mode="multiple"
														getPopupContainer={(triggerNode) =>
															triggerNode?.parentNode
														}
														showArrow
														showSearch
														filterOption={(input, option) =>
															option?.children
																?.toLowerCase()
																.indexOf(input?.toLowerCase()) >= 0
														}
														placeholder="Select"
														onSearch={debounce((value) => {
															onSearchPlatform(value);
														}, 300)}
													>
														{sortData(PlatformData?.data, "platform")?.map(
															(item, index) => {
																return (
																	<Option key={index} value={item?.lookupValue}>
																		{item?.lookupValue}
																	</Option>
																);
															}
														)}
													</Select>
												</Form.Item>
											</Col>
										</Row>
										{/* <Row>
											<Col span={24}>
												<Form.Item
													name="platform"
													rules={[
														{
															required: false,
															message: "Input something!",
														},
													]}
												>
													<div className="headBox">
														<Text className="label" type="secondary">
															Platform
														</Text>
														<Text
															// disabled={form.getFieldValue("platform")}
															disabled={
																				!formValues.area ||
																				formValues.area.length === 0
																			}
																			className={
																				!formValues.area ||
																				formValues.area.length === 0
																					? "resetDisabled"
																					: "reset"
																			}
															onClick={() => resetField("platform")}
															type="secondary"
														>
															reset
														</Text>
													</div>
													<Select
														mode="multiple"
														
														getPopupContainer={(triggerNode) =>
															triggerNode?.parentNode
														}
														showSearch
														filterOption={(input, option) =>
															option?.children
																?.toLowerCase()
																.indexOf(input?.toLowerCase()) >= 0
														}
														placeholder="Select"
														onSearch={debounce((value) => {
															onSearchPlatform(value);
														}, 300)}
													>
														{sortData(PlatformData?.data, "platform")?.map(
															(item, index) => {
																return (
																	<Option key={index} value={item?.lookupValue}>
																		{item?.lookupValue}
																	</Option>
																);
															}
														)}
													</Select>
												</Form.Item>
											</Col>
										</Row> */}

										<Row>
											<Col span={24} style={{ textAlign: "right" }}>
												<Space>
													<Button
														type="primary"
														htmlType="submit"
														className="applyBtn"
													>
														Apply Now
													</Button>
													<Button
														type="default"
														onClick={onReset}
														className="resetBtn"
													>
														Reset All
													</Button>
												</Space>
											</Col>
										</Row>
									</Form>
								</>
							}
						/>
					</div>

					<Spin spinning={loading} size="large">
						<div className="BlogBox">
							<Row className="RowBox mt30">
								<Col span={24}>
									<ul className="ProlistBox">
										{/* {InsightsFeedList?.error == 1009 ||
											InsightsFeedList?.total > 0 || <CustomEmpty />} */}
										{InsightsFeedList?.data?.length > 0 &&
										InsightsFeedList?.error != 1009 ? (
											InsightsFeedList?.data?.map((item, index) => {
												const date = new Date(item?.date);
												const formattedDate = formatDate(date);
												const showAll = showAllTags[index] || false;

												return (
													// <li style={{ padding: "20px 0" }} key={i}>
													// 	<div className="insight_list_cont">
													// 		<h3>
													// 			<div>
													// 				<div
													// 					style={{ cursor: "pointer" }}
													// 					onClick={() => {
													// 						toDetails(vo?.id);
													// 					}}
													// 				>
													// 					{vo?.title}
													// 				</div>
													// 			</div>
													// 			<div className="insight_list_date_style">
													// 				<Tooltip placement="top" title={"COPY"}>
													// 					<CopyOutlined
													// 						onClick={(e) => {
													// 							copy(
													// 								vo?.title +
													// 									"\n" +
													// 									vo?.content?.replace(
													// 										/<[^>]+>/g,
													// 										""
													// 									) +
													// 									"\n" +
													// 									vo?.link
													// 							);
													// 							message.success("Copied Success");
													// 						}}
													// 					/>
													// 				</Tooltip>
													// 				{formattedDate}
													// 			</div>
													// 		</h3>
													// 		<div className="Tag_List_Box">
													// 			{vo?.withCompanys?.map((vi, ii) => (
													// 				<Tag
													// 					key={ii}
													// 					color="#9E219E"
													// 					onClick={() => {
													// 						history.push(
													// 							`/games_organizations/organizations/details/${vi?.id}`
													// 						);
													// 					}}
													// 					style={{
													// 						cursor: "pointer",
													// 						borderRadius: "20px",
													// 					}}
													// 				>
													// 					{vi?.name}
													// 				</Tag>
													// 			))}
													// 			{vo?.withTeams?.map((vi, ii) => (
													// 				<Tag
													// 					key={ii}
													// 					color="blue"
													// 					onClick={() => {
													// 						history.push(`/Esports/Teams`);
													// 					}}
													// 				>
													// 					{vi?.esportTeamEn}
													// 				</Tag>
													// 			))}
													// 			{vo?.withGames?.map((vi, iii) => (
													// 				<Tag
													// 					key={iii}
													// 					color="#19AE54"
													// 					onClick={() => {
													// 						history.push(
													// 							`/games_organizations/games/details/${vi?.id}`
													// 						);
													// 					}}
													// 					style={{
													// 						cursor: "pointer",
													// 						borderRadius: "20px",
													// 					}}
													// 				>
													// 					{vi?.gameEn}
													// 				</Tag>
													// 			))}
													// 		</div>

													// 		<div
													// 			className="insght_lst_info overflowText"
													// 			onClick={() => {
													// 				toDetails(vo?.id);
													// 			}}
													// 		>
													// 			{vo?.content?.replace(/<[^>]+>/g, "")}
													// 		</div>
													// 		<a href={vo?.link} target="_blank">
													// 			{vo?.link}
													// 		</a>
													// 	</div>
													// </li>
													// <>
													// 	<div className="newsItem" key={index}>
													// 		<div
													// 			className="head"
													// 			onClick={() => {
													// 				history.push(
													// 					`/news_articles/vana/details/${item?.id}`
													// 				);
													// 				window.scroll(0, 0);
													// 			}}
													// 		>
													// 			<span>{item?.title}</span>
													// 			<span className="topDate">{formattedDate}</span>
													// 		</div>
													// 		{item?.withGames
													// 			?.slice(
													// 				0,
													// 				showAll ? item?.withGames?.length : 3
													// 			)
													// 			?.map((item) => (
													// 				<span
													// 					key={item?.id}
													// 					className="gameTags"
													// 					onClick={() => {
													// 						history.push({
													// 							pathname: "/news_articles/VANA",
													// 							state: { game: item?.id },
													// 						});
													// 					}}
													// 				>
													// 					{item?.gameEn}
													// 				</span>
													// 			))}
													// 		{item?.withCompanys
													// 			?.slice(
													// 				0,
													// 				showAll ? item?.withCompanys?.length : 3
													// 			)
													// 			?.map((item) => (
													// 				<span
													// 					key={item?.id}
													// 					className="companyTags"
													// 					onClick={() => {
													// 						history.push({
													// 							pathname: "/news_articles/VANA",
													// 							state: { company: item?.id },
													// 						});
													// 					}}
													// 				>
													// 					{item?.name}
													// 				</span>
													// 			))}
													// 		{(item?.withCompanys?.length > 3 ||
													// 			item?.withGames?.length > 3) && (
													// 			<span
													// 				className="show-all"
													// 				onClick={() => toggleShowAll(index)}
													// 			>
													// 				{showAll ? "Show less..." : "Show all..."}
													// 			</span>
													// 		)}
													// 		<div
													// 			className="text"
													// 			onClick={() => {
													// 				history.push(
													// 					`/news_articles/vana/details/${item?.id}`
													// 				);
													// 				window.scroll(0, 0);
													// 			}}
													// 		>
													// 			{item?.content?.replace(/<[^>]+>/g, "")}
													// 		</div>
													// 		<div className="link mt20">
													// 			<a
													// 				href={item?.link}
													// 				target="_blank"
													// 				onClick={(e) => copyVanaTop(e, item)}
													// 				style={{ marginRight: "2rem" }}
													// 			>
													// 				<img
													// 					src={imgSource?.copy_icon}
													// 					style={{ marginRight: "5px" }}
													// 				></img>
													// 				<span>Copy VANA</span>
													// 			</a>
													// 			<a href={item?.link} target="_blank">
													// 				<img
													// 					src={imgSource?.globe_icon}
													// 					style={{ marginRight: "5px" }}
													// 				></img>
													// 				<span>Visit Website</span>
													// 			</a>
													// 		</div>
													// 	</div>

													// 	<div className="breaker"></div>
													// </>
													<li key={index}>
														<div
															className="insight_list_cont"
															style={{ width: "100%" }}
														>
															<h3>
																<div>
																	<div
																		style={{ cursor: "pointer" }}
																		onClick={() => {
																			toDetails(item?.id);
																		}}
																	>
																		{item?.title}
																	</div>
																</div>
																<div className="insight_list_date_style">
																	{formattedDate}
																</div>
															</h3>
															<div style={{ display: "flex" }}>
																{item?.area?.length > 0 && (
																	<span
																		className="listLink"
																		onClick={() => {
																			const state = {
																				area: item?.area,
																			};
																			searchByTags(state);
																		}}
																	>
																		<img
																			src={imgSource?.map_icon}
																			style={{
																				marginRight: "5px",
																				width: "20px",
																			}}
																		></img>
																		{item?.area?.length > 0 &&
																			item?.area?.join(", ")}
																	</span>
																)}
																{item?.region?.length > 0 && (
																	<span
																		className="listLink"
																		onClick={() => {
																			const state = {
																				region: item?.region,
																			};
																			searchByTags(state);
																		}}
																	>
																		<img
																			src={imgSource?.globe_gray_icon}
																			style={{
																				marginRight: "5px",
																				width: "20px",
																			}}
																		></img>
																		{item?.region?.length > 0 &&
																			item?.region?.join(", ")}
																	</span>
																)}
																{item?.type && (
																	<span
																		className="listLink"
																		onClick={() => {
																			const state = {
																				type: item?.type,
																			};
																			searchByTags(state);
																		}}
																	>
																		<img
																			src={imgSource?.folder_icon}
																			style={{
																				marginRight: "5px",
																				width: "20px",
																			}}
																		></img>
																		{item?.type}
																	</span>
																)}
															</div>
															<div className="Tag_List_Box">
																{item?.withGames
																	?.slice(
																		0,
																		showAll ? item?.withGames?.length : 3
																	)
																	?.map((vi, iii) => (
																		<span
																			key={iii}
																			className="game-title"
																			onClick={() => {
																				history.push(
																					`/games_organizations/games/details/${vi?.id}`
																				);
																			}}
																		>
																			{vi?.gameEn}
																		</span>
																	))}
																{item?.withCompanys
																	?.slice(
																		0,
																		showAll ? item?.withCompanys?.length : 3
																	)
																	?.map((vi, ii) => (
																		<span
																			key={ii}
																			className="org-title"
																			onClick={() => {
																				history.push(
																					`/games_organizations/organizations/details/${vi?.id}`
																				);
																			}}
																		>
																			{vi?.name}
																		</span>
																	))}

																{(item?.withCompanys?.length > 3 ||
																	item?.withGames?.length > 3) && (
																	<span
																		className="show-all"
																		onClick={() => toggleShowAll(index)}
																	>
																		{showAll ? "Show less..." : "Show all..."}
																	</span>
																)}
															</div>
															<div
																className="insght_lst_info"
																onClick={() => {
																	toDetails(item?.id);
																}}
															>
																{(() => {
																	const content = item?.content?.replace(
																		/<[^>]+>/g,
																		""
																	);
																	const words = content?.split(" ");

																	if (words?.length > 50) {
																		return (
																			words?.slice(0, 50)?.join(" ") + "..."
																		);
																	} else {
																		return content;
																	}
																})()}
															</div>
															<div
																className="link"
																style={{ marginBottom: "20px" }}
															>
																<a
																	href={item?.link}
																	target="_blank"
																	onClick={(e) => copyVanaTop(e, item)}
																	style={{ marginRight: "2rem" }}
																>
																	<img
																		src={imgSource?.copy_icon}
																		style={{ marginRight: "5px" }}
																	></img>
																	<span>Copy VANA</span>
																</a>
																{item?.link && (
																	<a href={item?.link} target="_blank">
																		<img
																			src={imgSource?.external_link_icon}
																			style={{ marginRight: "5px" }}
																		></img>
																		<span>Visit Website</span>
																	</a>
																)}
															</div>
														</div>
													</li>
												);
											})
										) : (
											<CustomEmpty />
										)}
									</ul>
								</Col>

								<Row style={{ padding: "0 10px 0 10px", width: "100%" }}>
									<Col span={24}>
										{InsightsFeedList?.error != 1009 && InsightsFeedList?.total
											? InsightsFeedList?.total && (
													<Pagination
														current={InsightsFeedList?.page}
														total={InsightsFeedList?.total}
														pageSize={InsightsFeedList?.perPage}
														onChange={onPageChange}
														showSizeChanger
														showQuickJumper
														showTotal={(total, range) =>
															`${range[0]}-${range[1]} of ${total} items`
														}
														locale={paginationLocale}
														itemRender={(page, type, originalElement) => {
															if (type === "page") {
																return <span>{page}</span>;
															}
															return originalElement;
														}}
														className="custom-pagination"
													/>
											  )
											: ""}
									</Col>
								</Row>
							</Row>
						</div>
					</Spin>
				</>
			) : (
				customizeRenderEmpty()
			)}
		</div>
	);
};
