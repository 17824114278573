import React from "react";
import Iframe from "react-iframe";

const Report = () => {
	return (
		<div>
			<Iframe
				url="https://app.powerbi.com/view?r=eyJrIjoiZDZmMzQyZjMtZTRlMC00NGU3LThiZjctZTM4OTM5NTQxY2I1IiwidCI6Ijk5NzBiY2U0LTk3MjMtNDJkNS05OGJjLWZlYWQ3Y2VjNzAyMSIsImMiOjl9"
				position="absolute"
				width="80%"
				id="myId"
				className="myClassname"
				height="90%"
				frameBorder={1}
			/>
		</div>
	);
};

export default Report;
